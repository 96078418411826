import Vue from 'vue'

export default class ReChargeSubscriptionsAdapter {
  constructor () {
    this.state = Vue.observable({ showNibble: this.verifyNibbleEnabled() })
  }

  activate () {
    this.observer = new MutationObserver(() => {
      this.state.showNibble = this.verifyNibbleEnabled()
    })
    this.observer.observe(document, { childList: true, attributes: true, subtree: true })
  }

  deactivate () {
    if (this.observer != null) {
      this.observer.disconnect()
      this.observer = null
    }
  }

  verifyNibbleEnabled () {
    const subscriptionRadio = document.querySelector('input[value=subsave]')
    return subscriptionRadio != null && !subscriptionRadio.checked
  }
}
