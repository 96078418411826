import Cookies from 'cookies-js'

const setCookie = (cookieName, t, expirySeconds) => {
  Cookies.set(cookieName, t, { expires: expirySeconds })
}

const saveToStorage = (key, t, expirySeconds) => {
  if ('hasStorageAccess' in document) {
    document.hasStorageAccess().then(hasAccess => {
      if (hasAccess) {
        setCookie(key, t, expirySeconds)
      } else {
        document.requestStorageAccess().then(() => {
          setCookie(key, t, expirySeconds)
        })
      }
    })
  } else {
    setCookie(key, t, expirySeconds)
  }
}

const generateRandomString = (length) => {
  const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var outputChars = []
  for (let i = 0; i < length; i++) {
    outputChars[i] = validChars.charAt(Math.floor(Math.random() * validChars.length))
  }
  return outputChars.join('')
}

const setupAutoActivateCookie = () => {
  // Very short timeout on auto activate cookie as we only need to keep
  // it around for one page
  saveToStorage('nibble-auto-activate', 'true', 30)
}

const checkAutoActivateCookie = () => {
  return Cookies.get('nibble-auto-activate') != null
}

const deleteAutoActivateCookie = () => {
  Cookies.expire('nibble-auto-activate')
}

const getPageToken = () => {
  if (window.xNibblePageToken) {
    return window.xNibblePageToken
  } else {
    window.xNibblePageToken = generateRandomString(32)
    return window.xNibblePageToken
  }
}

const getCookie = (cookieName) => {
  return Cookies.get(cookieName)
}

export default {
  generateRandomString,
  setupAutoActivateCookie,
  checkAutoActivateCookie,
  deleteAutoActivateCookie,
  getPageToken,
  getCookie,
  setCookie,
  getT () {
    var t = Cookies.get('nibble')
    if (t == null) {
      t = generateRandomString(60)
    }
    saveToStorage('nibble', t, 3 * 60 * 60)
    return t
  }
}
