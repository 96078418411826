class DwellTimeTrigger {
  constructor (delay, callback) {
    this.delay = delay
    this.callback = callback
  }

  start () {
    this.timer = setTimeout(() => {
      this.trigger()
    }, this.delay * 1000)
  }

  stop () {
    if (this.timer != null) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }

  trigger () {
    this.timer = null
    this.callback(this.data)
  }
}

class ExitIntentTrigger {
  constructor (callback) {
    this.callback = callback
  }

  start () {
    this.timer = setTimeout(() => {
      this.timer = null
      this.exitListener = (e) => {
        this.testMouseExit(e)
      }
      document.addEventListener('mouseout', this.exitListener)
    }, 5000)
  }

  stop () {
    if (this.timer != null) {
      clearTimeout(this.timer)
      this.timer = null
    }
    if (this.exitListener != null) {
      document.removeEventListener('mouseout', this.exitListener)
    }
  }

  trigger () {
    if (this.exitListener != null) {
      document.removeEventListener('mouseout', this.exitListener)
    }
    this.callback(this.data)
  }

  testMouseExit (e) {
    const shouldShowExitIntent = !e.toElement && !e.relatedTarget && e.clientY < 10
    if (shouldShowExitIntent) {
      this.trigger()
    }
  }
}

class ImmediateTrigger {
  constructor (callback) {
    this.callback = callback
  }

  start () {
    this.callback(this.data)
  }

  stop () {}
}

function createTrigger (triggerType, delay, callback) {
  switch (triggerType) {
    case 'delay':
      return new DwellTimeTrigger(delay, callback)
    case 'exit_intent':
      return new ExitIntentTrigger(callback)
    case 'immediate':
      return new ImmediateTrigger(callback)
    default:
      return null
  }
}

export default createTrigger
