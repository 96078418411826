<template>
  <span class="xnib-tooltip-container">
    <span class="xnib-tooltip-popover">
      {{ infoPopupText }}
    </span>
  </span>
</template>

<script>

export default {
  name: 'InfoPopup',
  components: {},
  props: {
    theme: {
      type: Object,
      required: true
    },
    widgetContent: {
      type: Object,
      required: true
    }
  },
  computed: {
    infoPopupText () {
      if (this.widgetContent.error_message != null) {
        return this.widgetContent.error_message
      } else {
        return this.theme.text
      }
    }
  }
}
</script>

<style>
.xnib-tooltip-container {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
}
  .xnib-tooltip-popover {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 18px;
    transform: translateY(16px);
    box-shadow: 0 2px 32px rgba(0,0,0,.2);
    border-radius: 16px;
    text-align: center;
    box-sizing: border-box;
    display: none;
    font-size: 14px;
    line-height: 1.45;
    color: rgba(0,0,0,.6);
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    z-index: 2147483647;
  }

  .xnib-tooltip:hover .xnib-tooltip-popover {
    display: block;
  }

  .xnib-tooltip-popover:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 16px 8px;
    border-color: transparent transparent #ffffff transparent;
  }
</style>
