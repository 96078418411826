<template>
  <div :class="exitPopupClass">
    <div class="xnib-exit-backdrop">
      <div class="xnib-exit-popup">
        <div class="xnib-exit-popup-logo-container">
            <svg v-if="smartWhiteLabel" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.4" d="M15.2068 31.6795C16.0409 25.0072 21.7129 20 28.4372 20H51.563C58.2873 20 63.9593 25.0072 64.7934 31.6795L68.1267 58.3462C69.1214 66.3042 62.9163 73.3333 54.8963 73.3333H25.1039C17.0839 73.3333 10.8787 66.3042 11.8735 58.3462L15.2068 31.6795Z" fill="#8194A6"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M27.5 16.6666C27.5 9.76307 33.0964 4.16663 40 4.16663C46.9036 4.16663 52.5 9.76307 52.5 16.6666V23.3333C52.5 24.714 51.3807 25.8333 50 25.8333C48.6193 25.8333 47.5 24.714 47.5 23.3333V16.6666C47.5 12.5245 44.1421 9.16663 40 9.16663C35.8579 9.16663 32.5 12.5245 32.5 16.6666V23.3333C32.5 24.714 31.3807 25.8333 30 25.8333C28.6193 25.8333 27.5 24.714 27.5 23.3333V16.6666Z" fill="#8194A6"/>
              <path d="M30.4542 38.939L30.1007 39.2926C27.7575 41.6357 27.7575 45.4347 30.1007 47.7779L38.5859 56.2631C39.367 57.0442 40.6333 57.0442 41.4144 56.2631L49.8997 47.7779C52.2428 45.4347 52.2428 41.6357 49.8997 39.2926L49.5461 38.939C47.203 36.5959 43.404 36.5959 41.0608 38.939L40.0002 39.9997L38.9395 38.939C36.5964 36.5959 32.7974 36.5959 30.4542 38.939Z" fill="#8194A6"/>
            </svg>
            <svg v-else class="xnib-exit-popup-logo" width="76" height="75" viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M47.3257 0H28.5959C28.5959 15.5167 16.0571 28.0555 0.540405 28.0555V46.7853C0.540405 62.302 13.0792 74.8408 28.5959 74.8408H47.3257C62.8424 74.8408 75.3812 62.302 75.3812 46.7853V28.0555C75.4596 12.5388 62.8424 0 47.3257 0ZM14.0196 42.0833C14.0196 37.538 17.7029 33.8547 22.2482 33.8547C26.7935 33.8547 30.4767 37.538 30.4767 42.0833C30.4767 46.6286 26.7935 50.3118 22.2482 50.3118C17.7029 50.3118 14.0196 46.6286 14.0196 42.0833ZM48.031 61.1265C44.818 62.4588 41.4482 63.1641 38 63.1641C34.5518 63.1641 31.182 62.4588 27.969 61.1265C26.7935 60.6563 26.2449 59.2457 26.7151 58.0702C27.1853 56.8947 28.5959 56.3461 29.7714 56.8163C32.3575 57.9135 35.1004 58.462 38 58.462C40.8996 58.462 43.6424 57.9135 46.2286 56.8163C47.4041 56.3461 48.8147 56.8947 49.2849 58.0702C49.7551 59.2457 49.2065 60.6563 48.031 61.1265ZM53.8302 50.3118C49.2849 50.3118 45.6016 46.6286 45.6016 42.0833C45.6016 37.538 49.2849 33.8547 53.8302 33.8547C58.3755 33.8547 62.0588 37.538 62.0588 42.0833C62.0588 46.6286 58.2971 50.3118 53.8302 50.3118Z" fill="url(#paint0_linear)"/>
              <defs>
                <linearGradient id="paint0_linear" x1="37.961" y1="3.93663" x2="37.961" y2="72.608" gradientUnits="userSpaceOnUse">
                <stop stop-color="#259BFA"/>
                <stop offset="1" stop-color="#4276C5"/>
              </linearGradient>
              </defs>
            </svg>
          <div class="xnib-exit-popup-logo-shadow"/>
        </div>
        <div class="xnib-exit-popup-text-container">
          <h3 v-html="getTitle"></h3>
          <p v-html="getText"></p>
        </div>
        <div class="xnib-exit-popup-button-container">
          <div class="xnib-container show xnib-exit-popup-button-wrapper">
            <start-button @click="click" ref="button" :theme="getButtonTheme" :white-label="whiteLabel"/>
          </div>
          <div class="xnib-exit-popup-button-decline" @click="closeExitPopup" v-if="smartWhiteLabel">No, thanks</div>
          <div class="xnib-exit-popup-button-decline" @click="closeExitPopup" v-else>No, thanks!</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StartButton from '@/components/StartButton.vue'
import Utils from '@/utils/utils'

export default {
  name: 'ExitPopup',
  components: { StartButton },
  props: {
    buttonClick: {
      type: Function,
      required: true
    },
    theme: {
      type: Object,
      required: true
    },
    whiteLabel: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localTheme: {},
      visible: true
    }
  },
  computed: {
    exitPopupClass () {
      var classes = ['xnib-exit-container']
      if (this.visible) {
        classes.push('show')
      }
      if (this.whiteLabel) {
        classes.push('xnib-exit-white-label')
      }
      return classes
    },
    getButtonTheme () {
      if (this.localTheme?.button) {
        return this.localTheme.button
      }
      return null
    },
    showLogo () {
      if (this.localTheme?.exitPopup?.logo?.show === 'false') {
        return false
      }
      // Legacy logic
      return true
    },
    smartWhiteLabel () {
      return this.whiteLabel || !this.showLogo
    },
    getTitle () {
      if (this.localTheme?.exitPopup?.headingText) {
        return this.localTheme?.exitPopup?.headingText
      }
      // If WhiteLabel is set I'm returning the legacy value
      if (this.whiteLabel) {
        return 'Wait, shall we make a deal?'
      }
      return 'Wait, can we agree a better deal?'
    },
    getText () {
      if (this.localTheme?.exitPopup?.text) {
        return this.localTheme?.exitPopup?.text
      }
      // If WhiteLabel is set I'm returning the legacy value
      if (this.whiteLabel) {
        return 'Our virtual assistant can get you<br>a special price for this.'
      }
      return 'I\'m Nibble, the instant negotiation chatbot: no login needed, checkout as usual.<br/>Shall we talk a deal?'
    }
  },
  mounted () {
    // New theme configurator, I'm updating the theme button with the one defined in exitPopup
    // I'm merging, not replacing, to account for any missing data, just in case
    this.localTheme = { ...this.theme }
    if (this.localTheme.exitPopup?.button) {
      this.localTheme.button = Utils.deepMerge(this.localTheme.button, this.localTheme.exitPopup.button)
    }
    // Move the nibble-button element to the end of the document body in the DOM tree
    this.$parent.exitIntentPopupShown()
  },
  methods: {
    click () {
      this.$emit('buttonClick')
      this.closeExitPopup()
    },
    closeExitPopup () {
      this.$emit('exit')
      this.visible = false
    }
  }
}
</script>

<style>
.xnib-exit-container, .xnib-exit-container * {
  z-index: 2147483647;
}

.xnib-exit-container {
  position: fixed;
  visibility: none;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; }
  .xnib-exit-container .xnib-exit-backdrop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 24px;
    background-color: rgba(31, 38, 46, 0);
    transition: background-color 0.1s; }
  .xnib-exit-container.show {
    visibility: visible;
    pointer-events: auto; }
    .xnib-exit-container.show .xnib-exit-backdrop {
      background-color: rgba(31, 38, 46, 0.7);
}

.xnib-exit-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 40px;
  width: 100%;
  max-width: 0;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(31, 38, 46, 0.2);
  border-radius: 16px;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.5s ease-in-out;
  animation-duration: 0.75s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.xnib-exit-container.show .xnib-exit-popup {
  max-width: 400px;
  opacity: 1;
  animation-name: zoom-bounce;
}

@keyframes zoom-bounce {
  0% { transform: scale(0); }
  45% { transform: scale(1.05); }
  70% { transform: scale(0.95); }
  100% { transform: scale(1); }
}

.xnib-exit-popup-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center; }
  .xnib-exit-popup-logo {
    width: 75px;
    margin-bottom: 7px; }
  .xnib-exit-popup-logo-shadow {
    width: 85px;
    height: 14px;
    background: radial-gradient(
      49.31% 46.93% at 50% 49.84%,
      rgba(178, 179, 188, 0.3) 0%,
      rgba(178, 179, 188, 0) 100%
    );
    border-radius: 100%;
}

.xnib-exit-popup-text-container {
  font-family: Roboto, sans-serif; }
  .xnib-exit-popup-text-container h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #1F262E; }
  .xnib-exit-popup-text-container p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #3D4D5C;
}

.xnib-exit-popup-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px; }
  .xnib-exit-popup-button-wrapper { /* xnib-container overrides */
    margin: 0px 0px 15px 0px !important;
    padding: 0px !important; }
    .xnib-exit-popup-button-wrapper button {
      margin: 0px 0px 8px 0px !important;
      padding: 12px 15px !important;
      border-radius: 4px !important; }
    .xnib-exit-popup-button-wrapper button:hover {
      border-radius: 4px !important;
  }
  .xnib-exit-popup-button-decline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #668099;
    transition: color 0.2s; }
    .xnib-exit-popup-button-decline:hover {
      color: #1976D2;
}
</style>
