<template>
  <div class="xnib-info-box" :style="containerStyle">
    <div class="xnib-info-header">
      <svg width="40" height="46" viewBox="0 0 40 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.4238 0H15.539C15.539 7.4351 9.59106 13.4433 2.23047 13.4433V22.418C2.23047 29.8531 8.17842 35.8612 15.539 35.8612H24.4238C31.7844 35.8612 37.7323 29.8531 37.7323 22.418V13.4433C37.7695 6.00816 31.7844 0 24.4238 0ZM8.62452 20.1649C8.62452 17.9869 10.3717 16.222 12.5279 16.222C14.684 16.222 16.4312 17.9869 16.4312 20.1649C16.4312 22.3429 14.684 24.1078 12.5279 24.1078C10.3717 24.1078 8.62452 22.3429 8.62452 20.1649ZM24.7584 29.2898C23.2342 29.9282 21.6357 30.2661 20 30.2661C18.3643 30.2661 16.7658 29.9282 15.2416 29.2898C14.684 29.0645 14.4238 28.3886 14.6468 27.8253C14.8699 27.262 15.539 26.9992 16.0966 27.2245C17.3234 27.7502 18.6245 28.0131 20 28.0131C21.3755 28.0131 22.6766 27.7502 23.9033 27.2245C24.461 26.9992 25.1301 27.262 25.3531 27.8253C25.5762 28.3886 25.316 29.0645 24.7584 29.2898ZM27.5093 24.1078C25.3531 24.1078 23.6059 22.3429 23.6059 20.1649C23.6059 17.9869 25.3531 16.222 27.5093 16.222C29.6654 16.222 31.4126 17.9869 31.4126 20.1649C31.4126 22.3429 29.6282 24.1078 27.5093 24.1078Z" :fill="logoColor"/>
        <path d="M20 46C31.0457 46 40 44.4869 40 42.6204C40 40.7539 31.0457 39.2408 20 39.2408C8.9543 39.2408 0 40.7539 0 42.6204C0 44.4869 8.9543 46 20 46Z" fill="url(#paint0_radial_203_409)"/>
        <defs>
          <radialGradient id="paint0_radial_203_409" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20 42.6093) scale(19.7242 3.17187)">
            <stop stop-color="#BCA5A8" stop-opacity="0.3"/>
            <stop offset="1" stop-color="#BCB2B4" stop-opacity="0"/>
          </radialGradient>
        </defs>
      </svg>
      <span class="xnib-info-header-text" v-if="widgetContent.info_heading">{{widgetContent.info_heading}}</span>
      <span class="xnib-info-header-text" v-if="widgetContent.rich_info_heading">
        <span :class="item.weight === 'bold' ? 'xnib-info-bold' : 'xnib-info-normal'" v-for="(item, index) in widgetContent.rich_info_heading" :key="index">
          {{item.text}}
        </span>
      </span>
    </div>
    <p v-if="widgetContent.info_text_normal != null || widgetContent.info_text_bold != null">
      <strong v-if="widgetContent.info_text_bold != null">{{widgetContent.info_text_bold}}</strong>
      {{widgetContent.info_text_normal}}
    </p>
    <button :style="primaryButtonStyle" class="xnib-info-button-primary" v-if="widgetContent.primary_button_action != null" @click="primaryClick" ref="button">
      <span class="text">{{widgetContent.primary_button_text}}</span>
    </button>
    <button :style="secondaryButtonStyle" class="xnib-info-button-secondary" v-if="widgetContent.secondary_button_action != null" @click="secondaryClick" ref="button">
      <span class="text">{{widgetContent.secondary_button_text}}</span>
    </button>
  </div>
</template>

<script>
import Utils from '@/utils/utils'

export default {
  name: 'InfoBox',
  components: { },
  props: {
    theme: {
      type: Object,
      required: true
    },
    buttonAction: {
      type: String,
      required: true
    },
    widgetContent: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      localTheme: {}
    }
  },
  computed: {
    containerStyle () {
      var namespace = this.localTheme.container
      var prefix = '--xnib-container-'
      return {
        [`${prefix}max-width`]: namespace.maxWidth,
        [`${prefix}min-width`]: namespace.minWidth,
        [`${prefix}margin`]: namespace.margin,
        [`${prefix}padding`]: namespace.padding,
        [`${prefix}float`]: namespace.float,
        [`${prefix}border-color`]: this.borderColor
      }
    },
    primaryButtonStyle () {
      return {
        '--xnib-button-background-color': this.localTheme.button.backgroundColor,
        '--xnib-button-border-color': this.localTheme.button.backgroundColor,
        '--xnib-button-border-radius': this.localTheme.button.borderRadius,
        '--xnib-button-box-shadow': this.localTheme.button.boxShadow,
        '--xnib-button-height': this.localTheme.button.height,
        '--xnib-button-padding': this.localTheme.button.padding,
        '--xnib-button-transition': this.localTheme.button.transition,
        '--xnib-button-hover-color': this.localTheme.button.hover.color === 'auto' ? this.localTheme.button.color : this.localTheme.button.hover.color,
        '--xnib-button-hover-background-color': this.localTheme.button.hover.backgroundColor === 'auto' ? this.localTheme.button.backgroundColor : this.localTheme.button.hover.backgroundColor,
        '--xnib-button-hover-border-color': this.localTheme.button.hover.borderColor === 'auto' ? this.localTheme.button.backgroundColor : this.localTheme.button.hover.borderColor,
        '--xnib-button-hover-border-radius': this.localTheme.button.hover.borderRadius === 'auto' ? this.localTheme.borderRadius : this.localTheme.button.hover.borderRadius,
        '--xnib-button-hover-box-shadow': this.localTheme.button.hover.boxShadow === 'auto' ? this.localTheme.boxShadow : this.localTheme.button.hover.boxShadow,
        '--xnib-button-text-font-family': this.localTheme.button.fontFamily,
        '--xnib-button-text-font-weight': this.localTheme.button.fontWeight,
        '--xnib-button-text-font-style': this.localTheme.button.fontStyle,
        '--xnib-button-text-font-size': this.localTheme.button.fontSize,
        '--xnib-button-text-font-smoothing': this.localTheme.button.fontSmoothing,
        '--xnib-button-text-font-stretch': this.localTheme.button.fontStretch,
        '--xnib-button-text-color': this.localTheme.button.color,
        '--xnib-button-text-letter-spacing': this.localTheme.button.letterSpacing,
        '--xnib-button-text-text-transform': this.localTheme.button.textTransform
      }
    },
    secondaryButtonStyle () {
      return {
        '--xnib-button-background-color': this.localTheme.button.color,
        '--xnib-button-border-color': this.localTheme.button.backgroundColor,
        '--xnib-button-border-radius': this.localTheme.button.borderRadius,
        '--xnib-button-box-shadow': this.localTheme.button.boxShadow,
        '--xnib-button-height': this.localTheme.button.height,
        '--xnib-button-padding': this.localTheme.button.padding,
        '--xnib-button-transition': this.localTheme.button.transition,
        '--xnib-button-hover-color': this.localTheme.button.hover.color === 'auto' ? this.localTheme.button.backgroundColor : this.localTheme.button.hover.color,
        '--xnib-button-hover-background-color': this.localTheme.button.hover.color === 'auto' ? this.localTheme.button.color : this.localTheme.button.hover.color,
        '--xnib-button-hover-border-color': this.localTheme.button.hover.borderColor === 'auto' ? this.localTheme.button.backgroundColor : this.localTheme.button.hover.borderColor,
        '--xnib-button-hover-border-radius': this.localTheme.button.hover.borderRadius === 'auto' ? this.localTheme.borderRadius : this.localTheme.button.hover.borderRadius,
        '--xnib-button-hover-box-shadow': this.localTheme.button.hover.boxShadow === 'auto' ? this.localTheme.boxShadow : this.localTheme.button.hover.boxShadow,
        '--xnib-button-text-font-family': this.localTheme.fontFamily,
        '--xnib-button-text-font-weight': this.localTheme.fontWeight,
        '--xnib-button-text-font-style': this.localTheme.fontStyle,
        '--xnib-button-text-font-size': this.localTheme.fontSize,
        '--xnib-button-text-font-smoothing': this.localTheme.fontSmoothing,
        '--xnib-button-text-font-stretch': this.localTheme.fontStretch,
        '--xnib-button-text-color': this.localTheme.button.backgroundColor,
        '--xnib-button-text-letter-spacing': this.localTheme.letterSpacing,
        '--xnib-button-text-text-transform': this.localTheme.textTransform
      }
    },
    logoColor () {
      return this.localTheme.signposting.logoColor || '#1976D2'
    },
    borderColor () {
      if (this.buttonAction !== 'none') {
        return this.logoColor
      } else {
        return 'transparent'
      }
    }
  },
  beforeMount () {
    this.localTheme = { ...this.theme }
    if (this.localTheme.guidedCart?.signposting?.button) {
      this.localTheme.button = Utils.deepMerge(this.localTheme.button, this.localTheme.guidedCart.signposting.button)
    }
  },
  methods: {
    primaryClick () {
      this.$emit('primaryButtonClick')
    }
  }
}
</script>

<style lang="scss">
.xnib-info-box {
  max-width: var(--xnib-container-max-width);
  min-width: var(--xnib-container-min-width);
  margin: var(--xnib-container-margin);
  float: var(--xnib-container-float);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(31, 38, 46, 0.1);
  border-radius: 8px;
  border-width: 1px;
  border-color: var(--xnib-container-border-color);
  border-style: solid;
  .xnib-info-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      flex-shrink: 0;
    }
    .xnib-info-header-text {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      color: #1F262E;
      order: 1;
      flex-grow: 1;
      margin-left: 16px;
      .xnib-info-normal {
        font-weight: 400;
      }
      .xnib-info-bold {
        font-weight: 800;
      }
    }
  }
  p {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1F262E;
    margin: 14px 0 0;
  }
  .xnib-info-button-primary,
  .xnib-info-button-secondary {
    display: block;
    width: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    /* Customizable styling */
    background-color: var(--xnib-button-background-color);
    border-width: 2px;
    border-style: solid;
    border-color: var(--xnib-button-border-color);
    border-radius: var(--xnib-button-border-radius);
    box-shadow: var(--xnib-button-box-shadow);
    height: var(--xnib-button-height);
    margin: 8px 0 0 0;
    padding: var(--xnib-button-padding);

    span.text {
      /* Customizable styling */
      font-family: var(--xnib-button-text-font-family);
      font-weight: var(--xnib-button-text-font-weight);
      font-style: var(--xnib-button-text-font-style);
      font-size: var(--xnib-button-text-font-size);
      color: var(--xnib-button-text-color);
      -webkit-font-smoothing: var(--xnib-button-text-font-smoothing); /* macOS only */
      font-stretch: var(--xnib-button-text-font-stretch);
      letter-spacing: var(--xnib-button-text-letter-spacing);
      text-transform: var(--xnib-button-text-text-transform);
      display: flex;
      align-items: baseline;
      justify-content: center;
    }
    &:hover {
      /* Customizable styling */
      background-color: var(--xnib-button-hover-background-color);
      border-color: var(--xnib-button-hover-border-color);
      border-radius: var(--xnib-button-hover-border-radius);
      box-shadow: var(--xnib-button-hover-box-shadow);
    }

    &:hover span.text {
      /* Customizable styling */
      color: var(--xnib-button-hover-color);
    }
  }
}
</style>
