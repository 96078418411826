import Vue from 'vue'

export default class GlobalEAdapter {
  constructor () {
    // Default to hiding Nibble. Only show Nibble if Global-e is not active.
    this.state = Vue.observable({ showNibble: false })
    this.verifyNibbleEnabled()
  }

  activate () {
    this.observer = new MutationObserver(() => {
      this.verifyNibbleEnabled()
    })
    this.observer.observe(document, { childList: true, attributes: true, subtree: true })
  }

  deactivate () {
    if (this.observer != null) {
      this.observer.disconnect()
      this.observer = null
    }
  }

  verifyNibbleEnabled () {
    if (typeof window.GEM_Components !== 'undefined') {
      // Old Global-e code
      window.GEM_Components.ExternalMethodsComponent.IsOperatedByGlobalE(function (isOperated) {
        const updatedShowNibble = !isOperated
        if (updatedShowNibble !== this.state.showNibble) {
          console.log(`Global e adapter state changed to ${updatedShowNibble}`)
        }
        this.state.showNibble = updatedShowNibble
      }.bind(this))
    } else if (typeof window.GLBE_PARAMS !== 'undefined') {
      // Newer Global-e code
      const isOperated = window.GLBE_PARAMS.operatedCountries.indexOf(window.GLBE_PARAMS.countryCode) !== -1
      const updatedShowNibble = !isOperated
      if (updatedShowNibble !== this.state.showNibble) {
        console.log(`Global e adapter state changed to ${updatedShowNibble}`)
      }
      this.state.showNibble = updatedShowNibble
    } else {
      // Global-e is not present
      this.state.showNibble = true
      console.log(`Global e adapter state changed to ${this.state.showNibble}`)
    }
    return this.state.showNibble
  }
}
