<template>
  <button :class="buttonClass" :style="style" @click="click">
    <span class="xnib-skewBox">
        <div class="xnib-button-logo-container">
        <svg class="xnib-button-logo" viewBox="0 0 24 24" :width="style['--xnib-button-logo-size']" :height="style['--xnib-button-logo-size']" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Nibble Logo</title>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Desktop-HD" transform="translate(-667.000000, -484.000000)">
              <g id="Nibble-Logo" transform="translate(667.000000, 484.000000)">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                <path d="M9.49997685,2 C9.49997685,6.14220905 6.1421707,9.50023146 2,9.50023146 L2,9.50023146 L2,14.4999537 C2,18.6423479 5.35780615,22 9.49997685,22 L9.49997685,22 L14.5000231,22 C18.6421939,22 22,18.6423479 22,14.4999537 L22,14.4999537 L22,9.50023146 C22,5.35783724 18.6421939,2 14.5000231,2 L14.5000231,2 L9.49997685,2 Z M13.8556443,13.4198685 C13.8556443,11.9453754 15.0510781,10.7501157 16.5253724,10.7501157 L16.5253724,10.7501157 C17.9998519,10.7501157 19.1949153,11.9453754 19.1949153,13.4198685 L19.1949153,13.4198685 C19.1949153,14.8941765 17.9998519,16.0894362 16.5253724,16.0894362 L16.5253724,16.0894362 C15.0510781,16.0894362 13.8556443,14.8941765 13.8556443,13.4198685 L13.8556443,13.4198685 Z M5.10579478,13.4198685 C5.10579478,11.9453754 6.30085825,10.7501157 7.7753377,10.7501157 L7.7753377,10.7501157 C9.24981715,10.7501157 10.4448806,11.9453754 10.4448806,13.4198685 L10.4448806,13.4198685 C10.4448806,14.8941765 9.24981715,16.0894362 7.7753377,16.0894362 L7.7753377,16.0894362 C6.30085825,16.0894362 5.10579478,14.8941765 5.10579478,13.4198685 L5.10579478,13.4198685 Z" id="Fill-1"></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="xnib-button-text-container">
        <span class="text">{{ buttonText }}</span>
        <span class="text sub-text" v-if="buttonSubText">&nbsp;{{ buttonSubText }}</span>
      </div>
    </span>
  </button>
</template>

<script>
import { skewCSS } from '@/utils/theme'

export default {
  name: 'StartButton',
  components: {},
  props: {
    theme: {
      type: Object,
      required: true
    },
    buttonAction: {
      type: String,
      required: true
    },
    widgetContent: {
      type: Object,
      required: false
    },
    whiteLabel: {
      type: Boolean,
      default: false
    }
  },
  created () {
  },
  computed: {
    buttonText () {
      if (this.buttonAction === 'checkout') {
        return 'Check Out'
      } else if (this.buttonAction === 'applying_discount') {
        return 'Applying Discount...'
      } else {
        return this.theme.text
      }
    },
    buttonSubText () {
      if (this.buttonAction === 'checkout') {
        return ` - Save ${this.widgetContent.total_discount}`
      } else {
        return null
      }
    },
    buttonStyle () {
      var namespace = this.theme
      var prefix = '--xnib-button-'
      return {
        [`${prefix}background-color`]: namespace.backgroundColor,
        [`${prefix}border`]: namespace.border,
        [`${prefix}border-radius`]: namespace.borderRadius,
        [`${prefix}box-shadow`]: namespace.boxShadow,
        [`${prefix}height`]: namespace.height,
        [`${prefix}margin`]: namespace.margin,
        [`${prefix}padding`]: namespace.padding,
        [`${prefix}transition`]: namespace.transition,
        [`${prefix}skew`]: skewCSS(namespace.skewAx, namespace.skewAy),
        [`${prefix}skew-reverse`]: skewCSS(namespace.skewAx, namespace.skewAy, true)
      }
    },
    buttonHoverStyle () {
      var namespace = this.theme.hover
      var prefix = '--xnib-button-hover-'
      return {
        [`${prefix}color`]: namespace.color === 'auto' ? this.theme.color : namespace.color,
        [`${prefix}logo-color`]: namespace.logoColor === 'auto' ? this.theme.logo.color : namespace.logoColor,
        [`${prefix}background-color`]: namespace.backgroundColor === 'auto' ? this.theme.backgroundColor : namespace.backgroundColor,
        [`${prefix}border`]: namespace.border === 'auto' ? this.theme.border : namespace.border,
        [`${prefix}border-radius`]: namespace.borderRadius === 'auto' ? this.theme.borderRadius : namespace.borderRadius,
        [`${prefix}box-shadow`]: namespace.boxShadow === 'auto' ? this.theme.boxShadow : namespace.boxShadow,
        [`${prefix}skew`]: skewCSS(namespace.skewAx, namespace.skewAy),
        [`${prefix}skew-reverse`]: skewCSS(namespace.skewAx, namespace.skewAy, true)
      }
    },
    buttonLogoStyle () {
      var namespace = this.theme.logo
      var prefix = '--xnib-button-logo-'
      var style = {
        [`${prefix}color`]: namespace.color,
        [`${prefix}size`]: parseInt(namespace.size, 10) || 0,
        [`${prefix}margin-right`]: namespace.marginRight,
        [`${prefix}display`]: 'flex'
      }
      // If logo.show is false, set size and marginRight to 0
      if (namespace.show === false || namespace.show === 'false') {
        style[`${prefix}size`] = '0px'
        style[`${prefix}margin-right`] = '0px'
        style[`${prefix}display`] = 'none'
      }
      return style
    },
    buttonTextStyle () {
      var namespace = this.theme
      var prefix = '--xnib-button-text-'
      return {
        [`${prefix}font-family`]: namespace.fontFamily,
        [`${prefix}font-weight`]: namespace.fontWeight,
        [`${prefix}font-style`]: namespace.fontStyle,
        [`${prefix}font-size`]: namespace.fontSize,
        [`${prefix}font-smoothing`]: namespace.fontSmoothing,
        [`${prefix}font-stretch`]: namespace.fontStretch,
        [`${prefix}color`]: namespace.color,
        [`${prefix}letter-spacing`]: namespace.letterSpacing,
        [`${prefix}text-transform`]: namespace.textTransform
      }
    },
    buttonClass () {
      var classes = ['xnib-button']
      if (this.whiteLabel) {
        classes.push('xnib-button-white-label')
      }
      if (this.theme?.effect !== 'none') {
        classes.push(`xnib-button-effect-${this.theme?.effect}`)
      }
      return classes
    },
    style () {
      return {
        ...this.buttonStyle,
        ...this.buttonHoverStyle,
        ...this.buttonLogoStyle,
        ...this.buttonTextStyle
      }
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>

<style>
.xnib-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  /* Customizable styling */
  background-color: var(--xnib-button-background-color);
  border: var(--xnib-button-border);
  border-radius: var(--xnib-button-border-radius);
  box-shadow: var(--xnib-button-box-shadow);
  height: var(--xnib-button-height);
  margin: var(--xnib-button-margin);
  padding: var(--xnib-button-padding);
  transition: var(--xnib-button-transition); }

  .xnib-button .xnib-button-logo-container {
    display: var(--xnib-button-logo-display);
    /* Customizable styling */
    width: var(--xnib-button-logo-size);
    height: var(--xnib-button-logo-size);
    margin-right: var(--xnib-button-logo-margin-right); }

    .xnib-button .xnib-button-logo path {
      /* Customizable styling */
      fill: var(--xnib-button-logo-color); }

  .xnib-button-white-label .xnib-button-logo-container {
    display: none;
  }

  .xnib-button .xnib-button-text-container {
    /* Customizable styling */
    font-family: var(--xnib-button-text-font-family);
    font-weight: var(--xnib-button-text-font-weight);
    font-style: var(--xnib-button-text-font-style);
    font-size: var(--xnib-button-text-font-size);
    color: var(--xnib-button-text-color);
    -webkit-font-smoothing: var(--xnib-button-text-font-smoothing); /* macOS only */
    font-stretch: var(--xnib-button-text-font-stretch);
    letter-spacing: var(--xnib-button-text-letter-spacing);
    text-transform: var(--xnib-button-text-text-transform);
    display: flex;
    align-items: baseline;
  }

  .xnib-button span.sub-text {
    padding-left: 0.5rem;
    font-size: calc(var(--xnib-button-text-font-size) * 0.8);
  }

  .xnib-button:hover {
    /* Customizable styling */
    background-color: var(--xnib-button-hover-background-color);
    border : var(--xnib-button-hover-border);
    border-radius: var(--xnib-button-hover-border-radius);
    box-shadow: var(--xnib-button-hover-box-shadow); }

  .xnib-button:hover span.text {
    /* Customizable styling */
    color: var(--xnib-button-hover-color); }

  .xnib-button:hover .xnib-button-logo path {
    /* Customizable styling */
    fill: var(--xnib-button-hover-logo-color); }

  /* Skew effect */

  .xnib-button {
    transform: var(--xnib-button-skew);}

  .xnib-button .xnib-skewBox {
    display: flex;
    align-items: center;
    transform: var(--xnib-button-skew-reverse); }

  .xnib-button:hover {
    transform: var(--xnib-button-hover-skew);
   }
  .xnib-button:hover .xnib-skewBox{
    transform: var(--xnib-button-hover-skew-reverse); }

/* Button effects */

@keyframes pulse {
  0%, 66% { transform: scale(1); }
  33% { transform: scale(1.05); }
}

.xnib-button-effect-pulse {
  animation: 1s ease-in pulse infinite;
  transform-origin: center; }

  .xnib-button-effect-pulse:hover {
    animation: none;
}
</style>
