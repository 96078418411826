<template>
  <div :class="tooltipClass" :style="style" @click="click">
    <div class="xnib-tooltip-inner">{{ tooltipText }}</div>
    <info-popup :theme="theme.infoPopup" :widget-content="widgetContent" />
  </div>
</template>

<script>
import InfoPopup from '@/components/InfoPopup'

export default {
  name: 'InfoText',
  components: { InfoPopup },
  props: {
    theme: {
      type: Object,
      required: true
    },
    widgetContent: {
      type: Object,
      required: true
    },
    buttonAction: {
      type: String,
      required: true
    }
  },
  computed: {
    infoTextTheme () {
      return this.theme.infoText
    },
    tooltipText () {
      if (this.buttonAction !== 'negotiate' && this.widgetContent.error_heading != null) {
        return this.widgetContent.error_heading
      } else {
        return this.infoTextTheme.text
      }
    },
    tooltipStyle () {
      var namespace = this.infoTextTheme
      var prefix = '--xnib-tooltip-'
      return {
        [`${prefix}font-family`]: namespace.fontFamily,
        [`${prefix}font-weight`]: namespace.fontWeight,
        [`${prefix}font-size`]: namespace.fontSize,
        [`${prefix}color`]: namespace.color,
        [`${prefix}background-color`]: namespace.backgroundColor,
        [`${prefix}border`]: namespace.border,
        [`${prefix}border-radius`]: namespace.borderRadius,
        [`${prefix}height`]: namespace.height,
        [`${prefix}margin-bottom`]: namespace.marginBottom
      }
    },
    tooltipHoverStyle () {
      var namespace = this.infoTextTheme.hover
      var prefix = '--xnib-tooltip-hover-'
      return {
        [`${prefix}color`]: namespace.color === 'auto' ? this.infoTextTheme.color : namespace.color,
        [`${prefix}background-color`]: namespace.backgroundColor === 'auto' ? this.infoTextTheme.backgroundColor : namespace.backgroundColor,
        [`${prefix}border`]: namespace.border === 'auto' ? this.infoTextTheme.border : namespace.border,
        [`${prefix}border-radius`]: namespace.borderRadius === 'auto' ? this.infoTextTheme.borderRadius : namespace.borderRadius
      }
    },
    style () {
      return {
        ...this.tooltipStyle,
        ...this.tooltipHoverStyle
      }
    },
    tooltipClass () {
      var classes = ['xnib-tooltip']
      if (this.buttonAction !== 'negotiate' && this.widgetContent.error_heading == null) {
        classes.push('xnib-tooltip-hide')
      }
      return classes
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>

<style>
.xnib-tooltip {
  position: relative;
  text-align: center;
  letter-spacing: 0.2px;
  cursor: pointer;
  /* Customizable styling */
  font-family: var(--xnib-tooltip-font-family);
  font-weight: var(--xnib-tooltip-font-weight);
  font-size: var(--xnib-tooltip-font-size);
  color: var(--xnib-tooltip-color);
  background-color: var(--xnib-tooltip-background-color);
  border: var(--xnib-tooltip-border);
  border-radius: var(--xnib-tooltip-border-radius);
  height: var(--xnib-tooltip-height);
  margin-bottom: var(--xnib-tooltip-margin-bottom);
}

  .xnib-tooltip:hover {
    /* Customizable styling */
    color: var(--xnib-tooltip-hover-color);
    background-color: var(--xnib-tooltip-hover-background-color);
    border: var(--xnib-tooltip-hover-border);
    border-radius: var(--xnib-tooltip-hover-border-radius);
  }

  .xnib-tooltip .xnib-tooltip-inner {
    display: inline-block;
  }
  .xnib-tooltip.xnib-tooltip-hide {
    display: none;
  }
</style>
