const deepMerge = (target = null, source = null) => {
  if (!target) {
    return { ...source }
  }
  if (!source) {
    return { ...target }
  }

  const s = { ...source }
  // eslint-disable-next-line prefer-const
  let t = { ...target }

  if (s && typeof s === 'object') {
    for (const key in s) {
      // Both target and source have the key and its value is an object in both
      if (typeof t[key] === 'object' && !Array.isArray(t[key]) && typeof s[key] === 'object' && !Array.isArray(s[key])) {
        t[key] = deepMerge(t[key], s[key])
      } else {
        // Either the key doesn't exist in target or it's not an object, so we directly assign the source value to target
        t[key] = s[key]
      }
    }
  }

  return t
}

export default {
  deepMerge
}
