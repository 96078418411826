const defaultTheme = {
  container: {
    maxWidth: 'auto',
    minWidth: '224px',
    margin: '0px',
    padding: '0px',
    float: 'none'
  },
  button: {
    effect: 'none',
    text: 'Negotiate',
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '16px',
    fontSmoothing: 'auto',
    fontStretch: 'normal',
    color: '#FFFFFF',
    letterSpacing: '0.29px',
    textTransform: 'none',
    backgroundColor: '#1976D2',
    border: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
    height: 'auto',
    margin: '0px 0px 8px 0px',
    padding: '12px 15px',
    transition: 'border 0.2s, background-color 0.2s',
    logo: {
      color: '#FFFFFF',
      size: '24px',
      marginRight: '8px'
    },
    hover: {
      color: 'auto',
      logoColor: 'auto',
      backgroundColor: '#005EBB',
      border: 'auto',
      borderRadius: 'auto',
      boxShadow: 'auto'
    }
  },
  infoText: {
    enabled: 'true',
    text: 'Instant Chatbot - No Login Needed',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '500',
    fontSize: '14px',
    color: '#1976D2',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '0',
    height: '16px',
    marginBottom: '0px',
    hover: {
      color: 'auto',
      backgroundColor: 'auto',
      border: 'auto',
      borderRadius: 'auto'
    }
  },
  infoPopup: {
    text: 'Nibble allows you to quickly negotiate a price with a chatbot without entering any personal details, then checkout as normal.'
  },
  window: {
    marketing: {
      enabled: 'true'
    }
  },
  dwellTimePopup: {
    headingText: 'Still undecided?',
    text: "Let's talk a deal: instant chatbot, no login needed, checkout as usual.",
    logoGradientStart: '#4276C5',
    logoGradientEnd: '#259BFA'
  },
  signposting: {
    logoColor: '#1976D2',
    dealHeaderPrefix: "You're just",
    dealHeaderSuffix: 'away from your Special Deal',
    dealDescriptionSuffix: 'to your basket and negotiate a better deal in one minute with our virtual assistant',
    completeDealButtonText: 'Complete your deal',
    dealReadyHeader: 'You are now eligible for your special deal!',
    suggestionHeader: 'Do you like this suggestion?',
    suggestionMessage: "Let's carry on with our special deal",
    suggestionAddToBasketText: 'Yes, add it to my basket',
    suggestionOtherOptionsText: 'No, show me more options',
    recommendationsMessage: "Hi, I'm Nibble, your virtual assistant - let's complete your basket and get a great deal on it! Considering what you already have in your basket, here are my suggestions:"
  }
}

const mergeThemes = (baseTheme, priorityTheme) => {
  return [{}, baseTheme, priorityTheme].reduce(merge)
}

const merge = (a, b) => {
  /** Recursively merges nested objects
   * @see https://stackoverflow.com/a/56256929 */
  return Object.entries(b).reduce((o, [k, v]) => {
    o[k] = v && typeof v === 'object'
      ? merge(o[k] = o[k] || (Array.isArray(v) ? [] : {}), v)
      : v
    return o
  }, a)
}

const skewCSS = (ax, ay, reverse = false) => {
  if (ax) {
    ax = Number(ax.replace('deg', ''))
    ax = reverse ? -1 * ax : ax
  }
  if (ay) {
    ay = Number(ay.replace('deg', ''))
    ay = reverse ? -1 * ay : ay
  }
  if (ax && ay) {
    return `skew(${ax}deg, ${ay}deg)`
  } else if (ax) {
    return `skew(${ax}deg)`
  } else if (ay) {
    return `skew(0,${ay}deg)`
  }
  return 'skew(0, 0)'
}

export { defaultTheme, mergeThemes, skewCSS }
